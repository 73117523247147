import { SINGLE_ANSWER_MATRIX, MULTIPLE_INPUT, RANKING } from '@/constants/question-type'
import { MULTIPLE_ANSWER_MATRIX } from '../constants/question-type';
import { isEqual } from 'lodash';

export const BlockRandomizationMixin = {
  props: {
    question: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      categories: null
    }
  },

  watch: {
    'question.sub_questions' (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) this.updateCategories();
    },
    'question.answers' (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) this.updateCategories();
    }
  },

  methods: {
    updateCategories() {
      if (!this.qOpts?.block_randomization?.is_enabled || !this.qOpts?.block_randomization?.config) return;
  
      // Question types have sub question
      const types = [SINGLE_ANSWER_MATRIX, MULTIPLE_INPUT, RANKING, MULTIPLE_ANSWER_MATRIX]
      
      let blocks = this.qOpts?.block_randomization.config.map((block, index) => ({ ...block, order: index }));
      const shouldRandomBlocks = blocks.filter(block => block?.randomized_block);
      const savedBlockOrders = JSON.parse(sessionStorage.getItem(`blockRandomization-${this.question.qid}`));
      
      // Randomized block order
      if (shouldRandomBlocks.length > 0) {
        if (savedBlockOrders?.length > 0) {
          shouldRandomBlocks.forEach(block => block.order = savedBlockOrders.find(item => JSON.stringify(item?.title) === JSON.stringify(block?.title))?.order);
        } else {
          const blockOrders = shouldRandomBlocks.map(block => block.order).sort(() => Math.random() - 0.5);
          shouldRandomBlocks.forEach(block => block.order = blockOrders.shift());
        }
        blocks = blocks.sort((a, b) => a.order - b.order);
      }
      
      if (types.includes(this.question.type)) {
        this.categories = blocks.flatMap(block => {
          let subQuestions = this.question.sub_questions.filter(subQuestion => block.sub_questions?.includes(subQuestion.code));
          let savedSubQuestionOrders = savedBlockOrders?.find(item => JSON.stringify(item?.title) === JSON.stringify(block?.title))?.sub_questions;
  
          // Randomized items order
          if (block.randomized_items) {
            if (savedSubQuestionOrders?.length > 0) {
              subQuestions.forEach(subQuestion => subQuestion.order = savedSubQuestionOrders.find(item => item?.code === subQuestion?.code)?.order ?? 0);
            } else {
              const itemOrders = subQuestions.map(subQuestion => subQuestion.order).sort(() => Math.random() - 0.5);
              subQuestions.forEach(subQuestion => subQuestion.order = itemOrders.shift());
            }
            subQuestions = subQuestions.sort((a, b) => a.order - b.order);
          }

          if (subQuestions.length < 1) return [];

          return {
            ...block,
            sub_questions: subQuestions
          };
        });
  
      } else {
        this.categories = blocks.flatMap(block => {
          let answersArr = this.question.answers.filter(item => block.answers?.includes(item.code));
          let savedAnswerOrders = savedBlockOrders?.find(item => JSON.stringify(item?.title) === JSON.stringify(block?.title))?.answers;
          
          // Randomized items order
          if (block.randomized_items) {
            if (savedAnswerOrders?.length > 0) {
              answersArr.forEach(answer => answer.order = savedAnswerOrders.find(item => item?.code === answer?.code)?.order ?? 0);
            } else {
              const itemOrders = answersArr.map(answer => answer.order).sort(() => Math.random() - 0.5);
              answersArr.forEach(answer => answer.order = itemOrders.shift());
            }
            answersArr = answersArr.sort((a, b) => a.order - b.order);
          }

          if (answersArr.length < 1) return [];

          return {
            ...block,
            answers: answersArr
          };
          
        });
      }
    }
  },

  created () {
    this.updateCategories();
  },

  beforeDestroy () {
    if (this.categories) sessionStorage.setItem(`blockRandomization-${this.question.qid}`, JSON.stringify(this.categories));
  }
}
