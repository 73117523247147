<template>
  <ValidationProvider
      tag="div"
      class="question supported-keyboard column"
      :class="width"
      :rules="validationRules"
      v-slot="{ errors }"
  >
    <div class="question-text">
      <p class="question-text__code" v-if="showQuestionCode">
        {{ question.code }}
      </p>
      <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
      <p class="help" v-else>{{ questionHelp }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <template v-if="categories">
          <div :key="index" v-for="(category, index) in answers" class="category-content">
            <p v-if="category.display_title && category.answers.length > 0" class="category-title">{{ category.title[lang] || Object.values(category.title)[0] }}</p>
            <b-field v-for="answer in category.answers" :key="answer.id" class="mb-4">
              <b-checkbox-button
                  class="answer"
                  @input="onInput(question.qid, answer, $event)"
                  v-model="selected"
                  :native-value="answer.code"
                  :disabled="loading || disabled.includes(answer.code)"
              >
                <span class="keyboard" v-if="answer.key && showAnswerCode">{{ answer.key }}</span>
                <span class="option" v-html="answer.answer[lang]"></span>
              </b-checkbox-button>
            </b-field>
          </div>
        </template>
        <template v-else>
          <b-field v-for="answer in answers" :key="answer.id">
            <b-checkbox-button
                class="answer"
                @input="onInput(question.qid, answer, $event)"
                v-model="selected"
                :native-value="answer.code"
                :disabled="loading || disabled.includes(answer.code)"
            >
              <span class="keyboard" v-if="answer.key && showAnswerCode">{{ answer.key }}</span>
              <span class="option" v-html="answer.answer[lang]"></span>
            </b-checkbox-button>
          </b-field>
        </template>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { QuestionMixin } from "@/mixins/question";
import { KeyboardBindingMixin } from "@/mixins/keyboard-binding";
import { CHARACTERS } from "@/constants/vue-events";
import { UnFocusMixin } from "@/mixins/unfocus";
import _xor from "lodash.xor";
export default {
  name: "MultipleAnswer",
  mixins: [QuestionMixin, KeyboardBindingMixin, UnFocusMixin],
  data() {
    return {
      selected: [],
      disabled: [],
    };
  },
  methods: {
    onInput(qid, answer, selected){
      const isChecked = selected.includes(answer.code)
      const value = isChecked ? "1" : null;

      if (answer.options?.exclusive) {
        this.exclusive(qid, answer.code, isChecked);
      }
      const changes = { [this.questionKey(qid, null, answer.code)]: value };
      this.$emit("input", changes);
      this.unFocus();
    },
    exclusive(qid, acode, isChecked) {
      if (isChecked) {
        this.disabled = this.question.answers
            .filter((answer) => answer.code !== acode)
            .map((answer) => answer.code);
        const previousSelected = this.selected.filter(
            (answer) => answer !== acode
        );
        this.selected = [acode];
        const changes = previousSelected.reduce(
            (acc, acode) => ({
              ...acc,
              [this.questionKey(qid, null, acode)]: null,
            }),
            {}
        );
        this.$emit("input", changes);
      } else {
        this.disabled = [];
      }
      this.isExclusive = isChecked;
    },
    mapSelected() {
      return Object.keys(this.value)
          .filter(
              (key) =>
                  key.split("_")[0] === `${this.question.qid}` &&
                  this.value[key] === "1"
          )
          .map((qid) => qid.split("_")[2]);
    },
    _keyListener(e) {
      const deniedTags = ["textarea", "text", "number"];
      if (!CHARACTERS.includes(e.key) || deniedTags.includes(e.target.type))
        return;
      let answer = (this.categories ? this.answers.flatMap(item => item.answers) : this.answers).find((answer) => answer.key === e.key);
      if (!answer || this.disabled.includes(answer.code)) return;
      this.selected = _xor([...this.selected], [answer.code]);
      this.onInput(this.question.qid, answer, this.selected);
    },
  },
  created() {
    setTimeout(() => {
      this.selected = this.mapSelected() || [];
      const exclusiveAnswer = this.question.answers.find(
          (answer) =>
              answer?.options?.exclusive && this.selected.includes(answer.code)
      );
      if (exclusiveAnswer) {
        this.exclusive(this.question.qid, exclusiveAnswer.code, true);
      }
    }, 250);
  }
};
</script>
