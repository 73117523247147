import { CHARACTERS } from "@/constants/vue-events";
import _debounce from "lodash.debounce";
import { BlockRandomizationMixin } from "@/mixins/block-randomization";

export const KeyboardBindingMixin = {
  mixins: [BlockRandomizationMixin],
  methods: {
    _keyListener(e) {
      const deniedTags = ["textarea", "text", "number"];
      if (!CHARACTERS.includes(e.key) || deniedTags.includes(e.target.type) || !this.showAnswerCode)
        return;
      let answer = (this.categories ? this.answers.flatMap(item => item.answers) : this.answers).find((answer) => answer.key === e.key);
      if (!answer) return;
      this.onChange(answer.code);
    },
  },
  computed: {
    answers() {
      const answerArray = this.categories?.flatMap(item => item.answers) || this.question.answers
      if (answerArray.length > CHARACTERS.length)
        return this.categories || answerArray;
      if (this.categories) {
        return this.categories.map(category => {
          return {...category, answers: category.answers.map(answer => {
            return { ...answer, key: CHARACTERS[answerArray.findIndex(item => item.code === answer.code)] };
          })}
        });
      } else {
        return answerArray.map((answer, index) => {
          return { ...answer, key: CHARACTERS[index] };
        });
      }
    },
  },
  props: {
    showAnswerCode: {
      type: [String, Number, Boolean],
      default: true,
    },
  },
  mounted() {
    window.addEventListener("keypress", this._keyListener);
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this._keyListener);
  },
};

export const EnterSubmitMixin = {
  methods: {
    enterListener: _debounce(async function(e) {
      if (e.shiftKey === true) return false;
      if (e.which === 13) {
        if (!this.loading && !this.isScreenOut) {
          await this.onSubmit();
          return false;
        }
      }
    }, 500, { leading: true, trailing: false, maxWait: 1500})
  },
  mounted() {
    window.addEventListener("keypress", this.enterListener);
    window.customEnterSubmit = this;
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.enterListener);
  },
  computed: {
    isScreenOut(){
      return this.response?.is_screen_out;
    },
  }
};
